import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../containers/SaasModern/Documentation';
import ribbon_import from '../../../../../common/src/assets/image/ribbon_import.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal comes preloaded with a number of templates. However, if
        you'd like to add more templates to PrintableCal, including custom
        templates you've exported from another computer, you can do this by
        clicking the <strong>Import Template</strong> button on the PrintableCal
        tab of the Excel, Word, or PowerPoint ribbon.
        <br />
        <Image alt="" src={ribbon_import} style={{ width: 720 }} />
      </p>
      <p>
        After clicking the <strong>Import Template</strong> button, a window
        will appear where you can select the file to be imported. The file will
        be verified and then added to the list of available templates shown when
        the <Link to="/Documentation/Create-Calendar">Create Calendar</Link>{' '}
        button is clicked.
      </p>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Import-Template"
      commentsId="commentsplus_post_153_489"
      title="Import Additional Templates"
      description="PrintableCal includes many printable calendar templates. Calendar templates can be customized, exported from one computer, and imported on another computer."
      keywords="calendar templates, printable calendar, custom templates, customizable templates, import calendar template, Excel calendar, Word calendar, 2020 calendar"
      content={content}
    />
  );
};

export default Documentation;
